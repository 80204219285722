// Base.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { AppBar, Toolbar, IconButton, Button, Box, Menu, MenuItem, Fade, useTheme, useMediaQuery, Popper, Paper, ClickAwayListener, Container, Grid } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';

const Base = ({ onThemeToggle, darkState, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const [demosAnchorEl, setDemosAnchorEl] = useState(null);

  const handleMobileMenuClick = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const handleDemosClick = (event) => {
    setDemosAnchorEl(demosAnchorEl ? null : event.currentTarget);
  };

  const handleDemosClose = () => {
    setDemosAnchorEl(null);
  };

  const demosOpen = Boolean(demosAnchorEl);

  const DemosMenu = () => (
    <Popper 
      open={demosOpen} 
      anchorEl={demosAnchorEl} 
      transition 
      placement="bottom-end"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{
            background: darkState ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(10px)',
            border: '1px solid',
            borderColor: darkState ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
            overflow: 'hidden',
            minWidth: '120px',
            marginTop: '5px',
          }}>
            <ClickAwayListener onClickAway={handleDemosClose}>
              <Box>
                <MenuItem
                  onClick={() => {
                    window.open('https://research.deepvertical.ai/', '_blank');
                    handleDemosClose();
                  }}
                  sx={{
                    color: darkState ? theme.palette.primary.main : theme.palette.primary.dark,
                    '&:hover': {
                      backgroundColor: darkState ? 'rgba(0, 255, 255, 0.1)' : 'rgba(25, 118, 210, 0.1)',
                    },
                    transition: 'all 0.3s ease',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '10px 16px',
                  }}
                >
                  Research
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.open('https://screening.deepvertical.ai/', '_blank');
                    handleDemosClose();
                  }}
                  sx={{
                    color: darkState ? theme.palette.primary.main : theme.palette.primary.dark,
                    '&:hover': {
                      backgroundColor: darkState ? 'rgba(0, 255, 255, 0.1)' : 'rgba(25, 118, 210, 0.1)',
                    },
                    transition: 'all 0.3s ease',
                    justifyContent: 'center',
                    textAlign: 'center',
                    padding: '10px 16px',
                  }}
                >
                  Screening
                </MenuItem>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );

  return (
    <Box
      sx={{
        backgroundColor: darkState ? '#000000' : '#f3e5f5',
        color: darkState ? '#ffffff' : '#000000',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Helmet>
        <title>Deep Vertical AI</title>
        <meta property="og:title" content="Deep Vertical AI" />
        <meta property="og:description" content="An AI Boutique" />
        <meta property="og:image" content="https://deepvertical.ai/static/images/dvicon.png" />
        <meta property="og:url" content="https://deepvertical.ai/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Deep Vertical AI" />
        <meta name="twitter:description" content="The next big wave of AI is making it useful" />
        <meta name="twitter:image" content="https://deepvertical.ai/static/images/dvicon.png" />
      </Helmet>
      {!isMobile && (
        <AppBar
          position="fixed"
          sx={{
            top: 32,
            right: 32,
            backgroundColor: darkState ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
            borderRadius: '24px',
            boxShadow: 'none',
            padding: '2px 16px',
            width: 'auto',
            minWidth: '160px',
            border: '1px solid',
            borderColor: darkState ? 'rgba(127, 255, 212, 0.2)' : 'rgba(0, 255, 255, 0.2)',
          }}
        >
          <Toolbar>
            <Button color="inherit" component={Link} to="/" sx={{ color: darkState ? '#ffffff' : '#000000' }}>Home</Button>
            <Button color="inherit" component={Link} to="/insights" sx={{ color: darkState ? '#ffffff' : '#000000' }}>Insights</Button>
            <Button
              color="inherit"
              onClick={handleDemosClick}
              endIcon={<ExpandMoreIcon />}
              sx={{ 
                color: darkState ? '#ffffff' : '#000000',
                minWidth: '100px', // Ensure consistent width
                justifyContent: 'center', // Center text and icon
              }}
            >
              Demos
            </Button>
            <IconButton color="inherit" aria-label="toggle dark mode" onClick={onThemeToggle} sx={{ color: darkState ? '#ffffff' : '#000000' }}>
              {darkState ? <Brightness7Icon sx={{ color: '#00ffff' }} /> : <Brightness4Icon sx={{ color: '#7fffd4' }} />}
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {isMobile && (
        <>
          <IconButton
            color="inherit"
            aria-label="open menu"
            onClick={handleMobileMenuClick}
            sx={{ color: darkState ? '#ffffff' : '#000000', position: 'fixed', top: 32, right: 32 }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="mobile-menu"
            anchorEl={mobileMenuAnchorEl}
            keepMounted
            open={Boolean(mobileMenuAnchorEl)}
            onClose={handleMobileMenuClose}
            TransitionComponent={Fade}
          >
            <MenuItem component={Link} to="/" onClick={handleMobileMenuClose}>Home</MenuItem>
            <MenuItem component={Link} to="/insights" onClick={handleMobileMenuClose}>Insights</MenuItem>
            <MenuItem onClick={() => { handleDemosClick(); handleMobileMenuClose(); }}>Demos</MenuItem>
            <MenuItem onClick={() => { onThemeToggle(); handleMobileMenuClose(); }}>
              Toggle Theme
            </MenuItem>
          </Menu>
        </>
      )}
      <DemosMenu />
      <Container maxWidth={false} disableGutters sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {React.cloneElement(children, { darkState })}
          </Grid>
        </Grid>
      </Container>
      {/* Footer */}
      <Box
        component="footer"
        sx={{
          mt: 4,
          py: 4,
          textAlign: 'center',
          backgroundColor: 'transparent',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '1px',
            background: 'linear-gradient(to right, transparent, rgba(0, 255, 255, 0.3), transparent)',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '4px',
            background: 'linear-gradient(45deg, #00ffff, #7fffd4)',
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <IconButton color="inherit" aria-label="email" href="mailto:john@deepvertical.ai" sx={{ mx: 1 }}>
            <EmailIcon />
          </IconButton>
          <IconButton color="inherit" aria-label="github" href="https://github.com/johncollinsai" target="_blank" rel="noopener noreferrer" sx={{ mx: 1 }}>
            <GitHubIcon />
          </IconButton>
          <IconButton color="inherit" aria-label="linkedin" href="https://www.linkedin.com/in/johncollins-ai/" target="_blank" rel="noopener noreferrer" sx={{ mx: 1 }}>
            <LinkedInIcon />
          </IconButton>
          <IconButton color="inherit" aria-label="whatsapp" href="https://wa.me/6590301514" target="_blank" rel="noopener noreferrer" sx={{ mx: 1 }}>
            <WhatsAppIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Base;