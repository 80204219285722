// Background.js
import React, { useRef, useMemo } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, Line } from '@react-three/drei';
import * as THREE from 'three';

const colors = {
  dark: {
    background: '#000000',
    node: '#00ffff',
    edge: '#005f5f',
    particle: '#ffffff',
  },
  light: {
    background: '#f0f0f0',
    node: '#0066cc',
    edge: '#4d94ff',
    particle: '#000000',
  }
};

function OntologyNetwork({ count = 200, darkMode }) {
  const theme = darkMode ? colors.dark : colors.light;
  const pointsRef = useRef();
  const linesRef = useRef();
  const particlesRef = useRef();

  const [positions, connections, particles] = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const connections = [];
    const particles = new Float32Array(count * 3);

    for (let i = 0; i < count; i++) {
      const x = (Math.random() - 0.5) * 20;
      const y = (Math.random() - 0.5) * 20;
      const z = (Math.random() - 0.5) * 20;
      positions.set([x, y, z], i * 3);

      // Create connections
      for (let j = 0; j < 3; j++) {
        const connectedIndex = Math.floor(Math.random() * count);
        connections.push(i, connectedIndex);
      }

      particles.set([Math.random() * 20 - 10, Math.random() * 20 - 10, Math.random() * 20 - 10], i * 3);
    }

    return [positions, new Uint16Array(connections), particles];
  }, [count]);

  useFrame((state, delta) => {
    const time = state.clock.getElapsedTime();

    // Animate nodes
    for (let i = 0; i < positions.length; i += 3) {
      positions[i + 1] += Math.sin(time + positions[i]) * 0.01;
    }
    pointsRef.current.geometry.attributes.position.needsUpdate = true;

    // Animate particles
    for (let i = 0; i < particles.length; i += 3) {
      particles[i] += 0.05;
      if (particles[i] > 10) particles[i] = -10;
    }
    particlesRef.current.geometry.attributes.position.needsUpdate = true;

    // Rotate the entire network
    pointsRef.current.rotation.y = time * 0.05;
    linesRef.current.rotation.y = time * 0.05;
    particlesRef.current.rotation.y = time * 0.05;
  });

  return (
    <group>
      <Points ref={pointsRef} positions={positions}>
        <pointsMaterial color={theme.node} size={0.1} sizeAttenuation transparent opacity={0.8} />
      </Points>
      <lineSegments ref={linesRef}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={connections.length / 3}
            array={positions}
            itemSize={3}
          />
          <bufferAttribute
            attach="index"
            count={connections.length}
            array={connections}
            itemSize={1}
          />
        </bufferGeometry>
        <lineBasicMaterial color={theme.edge} opacity={0.5} transparent />
      </lineSegments>
      <Points ref={particlesRef} positions={particles}>
        <pointsMaterial color={theme.particle} size={0.05} sizeAttenuation transparent opacity={0.5} />
      </Points>
    </group>
  );
}

function Background({ darkMode }) {
  return (
    <Canvas camera={{ position: [0, 0, 30], fov: 60 }} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%' }}>
      <OntologyNetwork darkMode={darkMode} />
    </Canvas>
  );
}

export default Background;