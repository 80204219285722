import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import Base from './Base';

// Import the components for each route
import Homepage from './Homepage'; 
import Insights from './Insights';

// Lazy load the insight components
const ImplementingCommandRWithRAG = React.lazy(() => import('./insights/ImplementingCommandRWithRAG'));
const EMM = React.lazy(() => import('./insights/EMM'));
const ClaudeOpus = React.lazy(() => import('./insights/ClaudeOpus'));
const MultiModelAgents = React.lazy(() => import('./insights/MultiModelAgents'));
const LocalPlusCloud = React.lazy(() => import('./insights/LocalPlusCloud'));
const AIResearchAnalysis = React.lazy(() => import('./insights/AIResearchAnalysis'));
const OntologyAgent = React.lazy(() => import('./insights/OntologyAgent'));
const RealTimeUpdates = React.lazy(() => import('./insights/RealTimeUpdates'));

function App() {
  const [darkState, setDarkState] = useState(true);

  let theme = createTheme({
    palette: {
      mode: darkState ? 'dark' : 'light',
      primary: {
        main: darkState ? '#00ffff' : '#1976d2',
      },
      secondary: {
        main: darkState ? '#7fffd4' : '#dc004e',
      },
      background: {
        default: darkState ? '#000000' : '#f3e5f5',
        paper: darkState ? '#121212' : '#ffffff',
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h1: {
        fontWeight: 700,
      },
      h4: {
        fontWeight: 500,
      },
    },
  });
  theme = responsiveFontSizes(theme);

  const handleThemeChange = () => {
    setDarkState(!darkState);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Base title="Deep Vertical AI" onThemeToggle={handleThemeChange} darkState={darkState}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Homepage darkState={darkState} />} />
              <Route path="/insights" element={<Insights darkState={darkState} />} />
              <Route path="/insights/implementingcommandrwithrag" element={<ImplementingCommandRWithRAG darkState={darkState} />} />
              <Route path="/insights/emm" element={<EMM darkState={darkState} />} />
              <Route path="/insights/claudeopus" element={<ClaudeOpus darkState={darkState} />} />
              <Route path="/insights/multimodelagents" element={<MultiModelAgents darkState={darkState} />} />
              <Route path="/insights/localpluscloud" element={<LocalPlusCloud darkState={darkState} />} />
              <Route path="/insights/airesearchanalysis" element={<AIResearchAnalysis darkState={darkState} />} />
              <Route path="/insights/ontologyagent" element={<OntologyAgent darkState={darkState} />} />
              <Route path="/insights/realtimeupdates" element={<RealTimeUpdates darkState={darkState} />} />
            </Routes>
          </Suspense>
        </Base>
      </ThemeProvider>
    </Router>
  );
}

export default App;