// Homepage.js
import React from 'react';
import { Typography, Box, List, ListItem, ListItemText, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import Background from './Background';  // Make sure this path is correct

const Homepage = ({ darkState }) => {
  const theme = useTheme();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.3 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 }
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100vh',
        backgroundColor: darkState ? 'rgba(0, 0, 0, 0.7)' : 'rgba(243, 229, 245, 0.7)',
        color: darkState ? '#ffffff' : '#000000',
      }}
    >
      <Background darkMode={darkState} />
      <Box
        component={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          textAlign: 'center',
          px: 4,
          zIndex: 1,
          position: 'relative',
        }}
      >
        <motion.div variants={itemVariants}>
          <Typography variant="h1" component="h1" 
            sx={{ 
              fontSize: { xs: '3rem', md: '5rem' },
              fontWeight: 'bold',
              marginBottom: 4,
              background: darkState ? 'linear-gradient(45deg, #00ffff, #00bfff)' : 'linear-gradient(45deg, #1976d2, #1565c0)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textShadow: darkState ? '0 0 10px rgba(0, 255, 255, 0.5)' : 'none',
            }}
          >
            Deep Vertical AI
          </Typography>
        </motion.div>

        <motion.div variants={itemVariants}>
          <Typography variant="h4" sx={{ marginBottom: 4, maxWidth: '800px' }}>
            Deep Vertical AI specializes in developing cutting-edge AI solutions for enterprise clients. Our focus areas include:
          </Typography>
        </motion.div>

        <List>
          {['Large Language Models (LLMs)', 'Retrieval-Augmented Generation (RAG)', 'AI Agents and Multi-Model Systems', 'Domain-Specific AI Applications'].map((item, index) => (
            <ListItem key={index} component={motion.li} variants={itemVariants}>
              <ListItemText 
                primary={item} 
                primaryTypographyProps={{ 
                  variant: 'h6',
                  sx: { 
                    color: darkState ? theme.palette.primary.main : theme.palette.primary.dark,
                    fontWeight: 'bold',
                    textShadow: darkState ? '0 0 5px rgba(0, 255, 255, 0.5)' : 'none',
                  }
                }} 
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Homepage;